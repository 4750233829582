import React from "react";
import { GetCourseInfo, ScrollToTop } from "../../components";

const DataAnalytics = () => {
  return (
    <>
      <section className="bootCampHero">
        <div className="container">
          <div className="left">
            <div className="heroTxtContent">
              <h1>Data Analytics</h1>
            </div>
            <div className="btnHolder">
              <a href="../enroll" className="btn4">
                Enroll
              </a>
            </div>
          </div>
          <div className="right"></div>
        </div>
      </section>
      <main>
        <div className="benefits container">
          <div className="whatYouWillLearn">
            <div className="content">
              {/* <h1>What You Will Learn</h1> */}
              <article>
                <p>
                  Immerse yourself in the world of data with our specialized
                  course in Data Analytics, designed for individuals eager to
                  harness the power of information for insightful
                  decision-making. This program equips students with a
                  comprehensive skill set, blending statistical analysis and
                  data interpretation to navigate the complexities of the modern
                  data landscape.
                </p>
                <p>
                  Throughout the course, students will delve into the
                  fundamentals of data manipulation, cleansing, and
                  visualization, gaining proficiency in industry-standard tools
                  such as Python and R. They will explore various statistical
                  techniques to derive meaningful insights, transforming raw
                  data into actionable intelligence.
                </p>
                <p>
                  The curriculum emphasizes hands-on learning through real-world
                  projects and case studies, ensuring students are well-versed
                  in applying analytics methodologies to solve practical
                  problems. From predictive modeling to exploratory data
                  analysis, participants will develop a strong foundation in the
                  analytical techniques essential for extracting valuable
                  information from diverse datasets.
                </p>
                <p>
                  Collaborative projects and exposure to leading data analytics
                  platforms enhance teamwork and problem-solving skills,
                  mirroring the collaborative nature of the data analytics
                  industry. Students will also delve into data ethics and
                  privacy considerations, preparing them to navigate the ethical
                  dimensions of working with sensitive information.
                </p>
                <p>
                  Upon completion, graduates will be well-prepared to embark on
                  a variety of career paths in data analytics, whether in
                  business intelligence, finance, healthcare, or technology.
                  Join us and acquire the skills needed to turn data into
                  actionable insights, driving informed decision-making in
                  today's data-driven world.
                </p>
              </article>
            </div>
          </div>
          {/* <div className="pathWays">
            <div className="content">
              <h1>Employment Pathways</h1>
              <p>
                After successful completion of the training programme, Trainees
                have three employment pathways to choose from:
              </p>
              <ul>
                <li>
                  You can get an employment offer at one of our Service Centres.
                </li>
                <li>
                  You can use Veltetech as a platform to be self-employed as we
                  also encourage entrepreneurship.
                </li>
                <li>
                  You have an option to seek gainful employment on your own.
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="container">
          <GetCourseInfo />
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default DataAnalytics;
