import React from "react";
import { GetCourseInfo, ScrollToTop } from "../../components";

const FrontEndWebDevelopment = () => {
  return (
    <>
      <section className="bootCampHero">
        <div className="container">
          <div className="left">
            <div className="heroTxtContent">
              <h1>Frontend Web Development</h1>
            </div>
            <div className="btnHolder">
              <a href="../enroll" className="btn4">
                Enroll
              </a>
            </div>
          </div>
          <div className="right"></div>
        </div>
      </section>
      <main>
        <div className="benefits container">
          <div className="whatYouWillLearn">
            <div className="content">
              {/* <h1>What You Will Learn</h1> */}
              <article>
                <p>
                  Dive into the dynamic world of Frontend Web Development with
                  our specialized course, crafted for individuals eager to shape
                  the visual and interactive aspects of the digital landscape.
                  This program provides students with a comprehensive skill set,
                  encompassing the latest technologies and industry best
                  practices to thrive in the fast-paced field of web
                  development.
                </p>
                <p>
                  Throughout the course, students will master the core languages
                  of the web - HTML, CSS, and JavaScript - gaining proficiency
                  in creating responsive and visually appealing user interfaces.
                  They will delve into frontend frameworks such as React or
                  Vue.js, learning to build dynamic and interactive web
                  applications that meet modern user expectations.
                </p>
                <p>
                  The curriculum emphasizes hands-on learning through real-world
                  projects and collaborative exercises, ensuring students not
                  only understand the theory but can apply their knowledge to
                  practical scenarios. From designing intuitive user experiences
                  to optimizing web performance, participants will acquire the
                  skills necessary for crafting engaging and user-friendly
                  websites.
                </p>
                <p>
                  Collaboration is key, and students will have the opportunity
                  to work on team projects, simulating the collaborative nature
                  of the web development industry. They will also explore the
                  importance of web accessibility and responsive design,
                  ensuring their creations are inclusive and compatible across
                  various devices.
                </p>
                <p>
                  Upon completion, graduates will be well-equipped to enter the
                  workforce as frontend web developers, contributing their
                  creativity and technical skills to a range of industries. Join
                  us and transform your passion for coding into a rewarding
                  career, shaping the digital experiences of tomorrow.
                </p>
              </article>
            </div>
          </div>
          {/* <div className="pathWays">
            <div className="content">
              <h1>Employment Pathways</h1>
              <p>
                After successful completion of the training programme, Trainees
                have three employment pathways to choose from:
              </p>
              <ul>
                <li>
                  You can get an employment offer at one of our Service Centres.
                </li>
                <li>
                  You can use Veltetech as a platform to be self-employed as we
                  also encourage entrepreneurship.
                </li>
                <li>
                  You have an option to seek gainful employment on your own.
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="container">
          <GetCourseInfo />
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default FrontEndWebDevelopment;
