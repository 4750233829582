import React, { useState } from "react";
import { ScrollToTop } from "./../../components";
import "./webdev.css";
import { Notification } from "../../components";

const initialState = {
  fullname: "",
  email: "",
  companyDescription: "",
  servicesList: "",
  durationInBusiness: "",
  numberOfEmployees: "",
  targetMarket: "",
  targetMarketLocation: "",
  onlineCompetitors: "",
  uniquePropositions: "",
  sitePrimaryPurpose: "",
  siteSecondaryPurpose: "",
  siteOtherGoals: "",
  userActions: "",
  optionMakeSales: "",
  userLogin: "",
  additionalFeatures: "",
  likedElements: "",
  numberOfPages: "",
  contentCreation: "",
  contentCreatorAvailable: "",
  ownershipOfContent: "",
  videoOption: "",
  blogOption: "",
  brandGuidelines: "",
  favoriteWebsites: "",
  wordsOfIdentity: "",
  seoBefore: "",
  needSeo: "",
  keyPhrases: "",
  localSeo: "",
  mobileVisitors: "",
  socialHandles: "",
  preferredSocialMedia: "",
  currentDomain: "",
  hostingProvider: "",
  whyHostingProvider: "",
  maintenance: "",
  regularUpdateSections: "",
  mailMarketingService: "",
  pointPerson: "",
  approvalPerson: "",
  timeFrame: "",
};

const WebDevForms = () => {
  const [submitButtonValue, setSubmitButtonValue] = useState("Submit");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("success");

  const [formValues, setFormValues] = useState(initialState);

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitButtonValue("Sending");

    //console.log(formValues);

    fetch("http://localhost:5000/submit-project-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);

        if (data.success) {
          // console.log(formValues);

          // console.log("Form submitted successfully!");

          setFormValues(initialState);

          setNotificationMessage(data.message);
          setNotificationType("success");

          setShowNotification(true);

          // Close the notification after 3 seconds
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);

          // window.location.href = "/payments";
          //window.location.assign("/payments");

          // Show the payment modal after successful form submission
          // setOpenModal(true);
        } else {
          console.error("Form submission failed:", data.error);

          setNotificationMessage(
            data.message || "Form submission failed. Please try again."
          );
          setNotificationType("error");

          // Show the notification
          setShowNotification(true);
        }

        setSubmitButtonValue("Submit");
      })
      .catch((error) => {
        console.error("Error:", error);
        setNotificationMessage("An error occurred. Please try again later.");
        setNotificationType("error");

        // Show the notification
        setShowNotification(true);
        setSubmitButtonValue("Submit");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  return (
    <>
      <section id="questionaire">
        <div className="wrapper">
          <div className="container">
            <div className="heading">
              <h2>WEB DESIGN QUESTIONNAIRE</h2>
              <small>
                This questionnaire is for new web design sales prospects
              </small>
            </div>
            <form onSubmit={handleSubmit}>
              <h3>personal details</h3>
              <div className="form__group">
                <label for="Name">Your Full Name</label>
                <input
                  type="text"
                  name="fullname"
                  value={formValues.fullname}
                  onChange={handleInputChange}
                  id="name"
                  required
                />
              </div>
              <div className="form__group">
                <label for="Reference Name">Your Email</label>
                <input
                  type="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  id="email"
                  name="email"
                  required
                />
              </div>

              <h3>COMPANY</h3>
              <div className="form__group">
                <label for="name">
                  Describe your company in 1-­2 sentences:
                </label>
                <textarea
                  type="text"
                  value={formValues.companyDescription}
                  onChange={handleInputChange}
                  id="companyDescription"
                  name="companyDescription"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="email">
                  Please list each service and/or all products you offer:
                </label>
                <textarea
                  type="text"
                  value={formValues.servicesList}
                  onChange={handleInputChange}
                  id="servicesList"
                  name="servicesList"
                  rows="6"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="phone">How long have you been in business?</label>
                <textarea
                  type="text"
                  value={formValues.durationInBusiness}
                  onChange={handleInputChange}
                  id="durationInBusiness"
                  name="durationInBusiness"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  How many employees do you have?
                </label>
                <input
                  type="number"
                  value={formValues.numberOfEmployees}
                  onChange={handleInputChange}
                  id="numberOfEmployees"
                  name="numberOfEmployees"
                  required
                />
              </div>

              <div className="form__group">
                <label for="phone">Describe your target market:</label>
                <textarea
                  type="text"
                  value={formValues.targetMarket}
                  onChange={handleInputChange}
                  id="targetMarket"
                  name="targetMarket"
                  rows="6"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Where is your target market located?
                </label>
                <input
                  type="text"
                  value={formValues.targetMarketLocation}
                  onChange={handleInputChange}
                  id="targetMarketLocation"
                  name="targetMarketLocation"
                  required
                />
              </div>

              <div className="form__group">
                <label for="message">
                  List any online competitors and their website address:
                </label>
                <textarea
                  value={formValues.onlineCompetitors}
                  onChange={handleInputChange}
                  id="onlineCompetitors"
                  name="onlineCompetitors"
                  rows="6"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="message">
                  Why do people buy from you instead of your competitors?
                  (unique value propositions, price, your team, experience. etc)
                </label>
                <textarea
                  value={formValues.uniquePropositions}
                  onChange={handleInputChange}
                  id="uniquePropositions"
                  name="uniquePropositions"
                  rows="6"
                  required
                ></textarea>
              </div>

              <h3>NEW WEBSITE GOALS</h3>
              <div className="form__group">
                <label for="phone">
                  What is the primary purpose of your site? (share information,
                  leads, sales)
                </label>
                <textarea
                  type="text"
                  value={formValues.sitePrimaryPurpose}
                  onChange={handleInputChange}
                  id="sitePrimaryPurpose"
                  name="sitePrimaryPurpose"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="phone">
                  What is the secondary purpose of your site? (share
                  information, leads, sales)
                </label>
                <textarea
                  type="text"
                  value={formValues.siteSecondaryPurpose}
                  onChange={handleInputChange}
                  id="siteSecondaryPurpose"
                  name="siteSecondaryPurpose"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="phone">
                  Describe any other goals for your website.
                </label>
                <textarea
                  type="text"
                  value={formValues.siteOtherGoals}
                  onChange={handleInputChange}
                  id="siteOtherGoals"
                  name="siteOtherGoals"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="userActions">
                  What actions would you like a user to take when they visit
                  your site (please list in order of importance)?
                  <ul>
                    <small>For example:</small>

                    <li>Call you</li>
                    <li>Fill out a contact form</li>
                    <li>Fill out a quote form</li>
                    <li>Sign up for your mailing list</li>
                    <li>Search for information</li>
                    <li>Purchase a product(s)</li>
                  </ul>
                </label>
                <textarea
                  type="text"
                  value={formValues.userActions}
                  onChange={handleInputChange}
                  id="userActions"
                  name="userActions"
                  rows="8"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="phone">
                  Will your new website sell products and accept payments? (If
                  yes, please describe)
                </label>
                <textarea
                  type="text"
                  value={formValues.optionMakeSales}
                  onChange={handleInputChange}
                  id="optionMakeSales"
                  name="optionMakeSales"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="phone">
                  Will your visitors have an option to login? (If yes, please
                  describe)
                </label>
                <textarea
                  type="text"
                  value={formValues.userLogin}
                  onChange={handleInputChange}
                  id="userLogin"
                  name="userLogin"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="phone">
                  Are there any additional features or capabilities that you
                  would like for your new site to have?
                </label>
                <textarea
                  type="text"
                  value={formValues.additionalFeatures}
                  onChange={handleInputChange}
                  id="additionalFeatures"
                  name="additionalFeatures"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="phone">
                  What elements of your existing site do you like, and what do
                  you not like?
                </label>
                <textarea
                  type="text"
                  value={formValues.likedElements}
                  onChange={handleInputChange}
                  id="likedElements"
                  name="likedElements"
                  required
                ></textarea>
              </div>

              <h3>content</h3>

              <div className="form__group">
                <label for="Reference Name">
                  How many pages will your new website be?
                </label>
                <input
                  type="text"
                  value={formValues.numberOfPages}
                  onChange={handleInputChange}
                  id="numberOfPages"
                  name="numberOfPages"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Will you provide all content or would you like us to provide
                  that service?
                </label>
                <input
                  type="text"
                  value={formValues.contentCreation}
                  onChange={handleInputChange}
                  id="contentCreation"
                  name="contentCreation"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Do you have someone that can help with content?
                </label>
                <input
                  type="text"
                  value={formValues.contentCreatorAvailable}
                  onChange={handleInputChange}
                  id="contentCreatorAvailable"
                  name="contentCreatorAvailable"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Do you have ownership of all images for the site, or will you
                  require purchasing stock photography?
                </label>
                <input
                  type="text"
                  value={formValues.ownershipOfContent}
                  onChange={handleInputChange}
                  id="ownershipOfContent"
                  name="ownershipOfContent"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Do you have videos you’d like incorporated into the site?
                </label>
                <input
                  type="text"
                  value={formValues.videoOption}
                  onChange={handleInputChange}
                  id="videoOption"
                  name="videoOption"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Will you publish a blog on the site?
                </label>
                <input
                  type="text"
                  value={formValues.blogOption}
                  onChange={handleInputChange}
                  id="blogOption"
                  name="blogOption"
                  required
                />
              </div>

              <h3>Design</h3>

              <div className="form__group">
                <label for="Reference Name">
                  Do you have brand guidelines, logo(s), and brand colors to
                  incorporate into the site?
                </label>
                <input
                  type="text"
                  value={formValues.brandGuidelines}
                  onChange={handleInputChange}
                  id="brandGuidelines"
                  name="brandGuidelines"
                  required
                />
              </div>

              <div className="form__group">
                <label for="phone">
                  Please provide 3 examples of websites you like, and describe
                  why:
                </label>
                <textarea
                  type="text"
                  value={formValues.favoriteWebsites}
                  onChange={handleInputChange}
                  id="favoriteWebsites"
                  name="favoriteWebsites"
                  rows="3"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="phone">
                  What are three words that best portray your company’s image?
                  (professional, polished, modern, fun, creative, simple, etc.)
                </label>
                <textarea
                  type="text"
                  value={formValues.wordsOfIdentity}
                  onChange={handleInputChange}
                  id="wordsOfIdentity"
                  name="wordsOfIdentity"
                  rows="3"
                  required
                ></textarea>
              </div>

              <h3>SEO</h3>

              <div className="form__group">
                <label for="phone">
                  Have you preformed SEO on an older or current version of your
                  existing site?
                </label>
                <textarea
                  type="text"
                  value={formValues.seoBefore}
                  onChange={handleInputChange}
                  id="seoBefore"
                  name="seoBefore"
                  rows="3"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Will you need SEO for this new site?
                </label>
                <input
                  type="text"
                  value={formValues.needSeo}
                  onChange={handleInputChange}
                  id="needSeo"
                  name="needSeo"
                  required
                />
              </div>

              <div className="form__group">
                <label for="phone">
                  Please provide a few example key phrases you would like to
                  target in search engines.
                </label>
                <textarea
                  type="text"
                  value={formValues.keyPhrases}
                  onChange={handleInputChange}
                  id="keyPhrases"
                  name="keyPhrases"
                  rows="3"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Do you provide a local service to your city? (Local SEO)
                </label>
                <input
                  type="text"
                  value={formValues.localSeo}
                  onChange={handleInputChange}
                  id="localSeo"
                  name="localSeo"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Do you expect mobile visitors?
                </label>
                <input
                  type="text"
                  value={formValues.mobileVisitors}
                  onChange={handleInputChange}
                  id="mobileVisitors"
                  name="mobileVisitors"
                  required
                />
              </div>

              <h3>SOCIAL MEDIA</h3>

              <div className="form__group">
                <label for="Reference Name">
                  What social media channels does your company currently use?
                </label>
                <input
                  type="text"
                  value={formValues.socialHandles}
                  onChange={handleInputChange}
                  id="socialHandles"
                  name="socialHandles"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  What social media sites would your company like to start using
                  in the future?
                </label>
                <input
                  type="text"
                  value={formValues.preferredSocialMedia}
                  onChange={handleInputChange}
                  id="preferredSocialMedia"
                  name="preferredSocialMedia"
                  required
                />
              </div>

              <h3>Technical</h3>

              <div className="form__group">
                <label for="Reference Name">
                  What is your current domain or URL?
                </label>
                <input
                  type="text"
                  value={formValues.currentDomain}
                  onChange={handleInputChange}
                  id="currentDomain"
                  name="currentDomain"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Where is your current site hosted?
                </label>
                <input
                  type="text"
                  value={formValues.hostingProvider}
                  onChange={handleInputChange}
                  id="hostingProvider"
                  name="hostingProvider"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Why do you use your current host?
                </label>
                <input
                  type="text"
                  value={formValues.whyHostingProvider}
                  onChange={handleInputChange}
                  id="whyHostingProvider"
                  name="whyHostingProvider"
                  required
                />
              </div>

              <h3>MAINTENANCE</h3>

              <div className="form__group">
                <label for="Reference Name">
                  Will you need monthly maintenance and updates after launch?
                </label>
                <input
                  type="text"
                  value={formValues.maintenance}
                  onChange={handleInputChange}
                  id="maintenance"
                  name="maintenance"
                  required
                />
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  What sections of the site will need regular updating after
                  launch?
                </label>
                <textarea
                  type="text"
                  value={formValues.regularUpdateSections}
                  onChange={handleInputChange}
                  id="regularUpdateSections"
                  name="regularUpdateSections"
                  required
                ></textarea>
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Do you use an email marketing service that you’d like
                  integrated? (Mailchimp, Constant Contact, etc.)
                </label>
                <textarea
                  type="text"
                  value={formValues.mailMarketingService}
                  onChange={handleInputChange}
                  id="mailMarketingService"
                  name="mailMarketingService"
                ></textarea>
              </div>

              <h3>PROJECT MANAGEMENT</h3>

              <div className="form__group">
                <label for="Reference Name">
                  Who will be the point person for this project, and who will
                  provide approvals? Please provide their contact information.
                </label>
                <textarea
                  type="text"
                  value={formValues.pointPerson}
                  onChange={handleInputChange}
                  id="pointPerson"
                  name="pointPerson"
                ></textarea>
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  Please list the person who has final approval on all aspects
                  of this project:
                </label>
                <textarea
                  type="text"
                  value={formValues.approvalPerson}
                  onChange={handleInputChange}
                  id="approvalPerson"
                  name="approvalPerson"
                ></textarea>
              </div>

              <div className="form__group">
                <label for="Reference Name">
                  What is your ideal time frame or launch date?
                </label>
                <textarea
                  type="text"
                  value={formValues.timeFrame}
                  onChange={handleInputChange}
                  id="timeFrame"
                  name="timeFrame"
                ></textarea>
              </div>

              <input type="submit" value={submitButtonValue} className="btn3" />
            </form>
          </div>
        </div>
        <ScrollToTop />
      </section>

      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default WebDevForms;
