import React from "react";
import { GetCourseInfo, ScrollToTop } from "../../components";

const GraphicDesign = () => {
  return (
    <>
      <section className="bootCampHero">
        <div className="container">
          <div className="left">
            <div className="heroTxtContent">
              <h1>Graphic Design</h1>
            </div>
            <div className="btnHolder">
              <a href="../enroll" className="btn4">
                Enroll
              </a>
            </div>
          </div>
          <div className="right"></div>
        </div>
      </section>
      <main>
        <div className="benefits container">
          <div className="whatYouWillLearn">
            <div className="content">
              {/* <h1>What You Will Learn</h1> */}
              <article>
                <p>
                  Embark on a creative journey in the world of visual
                  communication with our Graphic Design course. Designed for
                  aspiring artists and communicators, this program provides
                  students with a comprehensive set of skills to thrive in the
                  dynamic field of graphic design. Throughout the course,
                  students will master industry-standard design software, honing
                  their abilities to create compelling visual narratives,
                  eye-catching branding, and impactful multimedia content.
                </p>
                <p>
                  Our curriculum emphasizes a hands-on approach, ensuring
                  students gain practical experience in conceptualizing,
                  designing, and executing a wide range of visual projects. From
                  developing a keen understanding of design principles to
                  exploring the nuances of color theory and typography, students
                  will cultivate a refined aesthetic sense.
                </p>
                <p>
                  The course goes beyond technical proficiency, fostering
                  creativity and innovation. Students will learn to think
                  critically about design challenges, developing problem-solving
                  skills essential in the professional realm. Collaborative
                  projects and real-world simulations provide opportunities to
                  work in a team, simulating the collaborative nature of the
                  graphic design industry.
                </p>
                <p>
                  Upon completion of the program, students will be equipped to
                  navigate the diverse landscape of graphic design, ready to
                  contribute their unique perspectives to advertising agencies,
                  design studios, publishing houses, or as independent
                  freelancers. Join us and unleash your creative potential,
                  turning imagination into impactful visual communication.
                </p>
              </article>
            </div>
          </div>
          {/* <div className="pathWays">
            <div className="content">
              <h1>Employment Pathways</h1>
              <p>
                After successful completion of the training programme, Trainees
                have three employment pathways to choose from:
              </p>
              <ul>
                <li>
                  You can get an employment offer at one of our Service Centres.
                </li>
                <li>
                  You can use Veltetech as a platform to be self-employed as we
                  also encourage entrepreneurship.
                </li>
                <li>
                  You have an option to seek gainful employment on your own.
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="container">
          <GetCourseInfo />
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default GraphicDesign;
