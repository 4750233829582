import React from "react";
import { GetCourseInfo, ScrollToTop } from "../../components";

const WebDevelopment = () => {
  return (
    <>
      <section className="bootCampHero">
        <div className="container">
          <div className="left">
            <div className="heroTxtContent">
              <h1>Fullstack Web Development</h1>
            </div>
            <div className="btnHolder">
              <a href="../enroll" className="btn4">
                Enroll
              </a>
            </div>
          </div>
          <div className="right"></div>
        </div>
      </section>
      <main>
        <div className="benefits container">
          <div className="whatYouWillLearn">
            <div className="content">
              {/* <h1>What You Will Learn</h1> */}
              <article>
                <p>
                  Embark on a dynamic journey into the world of web development
                  with our comprehensive course designed for aspiring coders and
                  tech enthusiasts. Our Web Development program equips students
                  with the essential skills and knowledge needed to thrive in
                  the ever-evolving digital landscape.
                </p>
                <p>
                  Throughout the course, students will delve into the
                  fundamentals of web development, mastering key programming
                  languages such as HTML, CSS, and JavaScript. They will gain
                  hands-on experience in creating responsive and visually
                  appealing websites, ensuring a solid foundation in user
                  interface (UI) and user experience (UX) design principles.
                </p>
                <p>
                  As technology advances, our curriculum evolves to cover the
                  latest frameworks and tools in the industry, empowering
                  students with the ability to build robust and interactive web
                  applications. From front-end development, focusing on the
                  visual aspects of a website, to back-end development, managing
                  server-side functionality, students will acquire a holistic
                  understanding of the web development process.
                </p>
                <p>
                  Problem-solving and critical thinking are emphasized
                  throughout the program, as students tackle real-world
                  challenges and collaborate on projects that mirror the
                  complexities of the tech industry. They will also learn
                  version control using platforms like Git, preparing them for
                  collaborative work environments.
                </p>
                <p>
                  Upon completion, students will be well-prepared to enter the
                  workforce as junior web developers, equipped with a portfolio
                  showcasing their proficiency in building dynamic and
                  user-friendly websites. Join us and unlock the door to a world
                  of opportunities in the exciting field of web development.
                </p>
              </article>
            </div>
          </div>
          {/* <div className="pathWays">
            <div className="content">
              <h1>Employment Pathways</h1>
              <p>
                After successful completion of the training programme, Trainees
                have three employment pathways to choose from:
              </p>
              <ul>
                <li>
                  You can get an employment offer at one of our Service Centres.
                </li>
                <li>
                  You can use Veltetech as a platform to be self-employed as we
                  also encourage entrepreneurship.
                </li>
                <li>
                  You have an option to seek gainful employment on your own.
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="container">
          <GetCourseInfo />
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default WebDevelopment;
