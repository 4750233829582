import React from "react";
import "./portfolio.css";
import ScrollToTop from "./../../components/ScrollToTop";

const Portfolio = () => {
  return (
    <>
      <section id="portfolio">
        <div class="portfolio-container">
          <div class="portfolio-text-box">
            <p>Our</p>
            <h1>Portfolio</h1>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Portfolio;
