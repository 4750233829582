import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import {
  Home,
  ServicePage,
  NotFound,
  Portfolio,
  AboutUs,
  ContactPage,
  WebDevForms,
  BootCamp,
  Enroll,
  BlenderCourse,
  GraphicDesign,
  MobileDevelopment,
  WebDevelopment,
  UiUx,
  DataAnalytics,
  FrontEndWebDevelopment,
} from "./pages";

import Layout from "./Layouts/Layout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index exact element={<Home />} />

      <Route path="/services" element={<ServicePage />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/bootcamp" element={<BootCamp />} />
      <Route path="/webdevforms" element={<WebDevForms />} />
      <Route path="/enroll" element={<Enroll />} />
      <Route path="*" element={<NotFound />} />

      <Route
        path="/bootcamp/3d-graphic-design-with-blender"
        element={<BlenderCourse />}
      />
      <Route path="/bootcamp/graphic-design" element={<GraphicDesign />} />
      <Route
        path="/bootcamp/mobile-development"
        element={<MobileDevelopment />}
      />
      <Route path="/bootcamp/web-development" element={<WebDevelopment />} />
      <Route
        path="/bootcamp/frontend-web-development"
        element={<FrontEndWebDevelopment />}
      />
      <Route path="/bootcamp/ui-ux" element={<UiUx />} />
      <Route path="/bootcamp/data-analytics" element={<DataAnalytics />} />

      {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> */}
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
