import React, { useState } from "react";
import "./enroll.css";
import ScrollToTop from "./../../components/ScrollToTop";
import { Notification } from "../../components";

const Enroll = () => {
  const [submitButtonValue, setSubmitButtonValue] = useState("Send");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("success");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [certificateObtained, setCertificateObtained] = useState("");
  const [courseOption, setCourseOption] = useState("");
  const [passportPicture, setPassportPicture] = useState(null);
  const [formKey, setFormKey] = useState(0);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPassportPicture(file);
  };

  const courseOptions = [
    "3D Graphic Design with Blender",
    "Graphic Design",
    "Fullstack Web Development",
    "Mobile Development",
    "Frontend Web Development",
    "Data Analytics",
    "UI/UX",
  ];

  const handleCourseOptionChange = (e) => {
    setCourseOption(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitButtonValue("Sending");

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("educationLevel", educationLevel);
    formData.append("certificateObtained", certificateObtained);
    formData.append("courseOption", courseOption);
    formData.append("passportPicture", passportPicture);

    fetch("http://localhost:5000/enroll-form-submit", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then((data) => {
        //console.log(data.message);

        // Log form data values
        // for (const [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }

        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setEducationLevel("");
        setCertificateObtained("");
        setCourseOption("");
        setPassportPicture(null);

        // Reset the form by incrementing the key
        setFormKey((prevKey) => prevKey + 1);

        setNotificationMessage(data.message);
        setNotificationType("success");

        setShowNotification(true);

        // Close the notification after 3 seconds
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setNotificationMessage("An error occurred. Please try again later.");
        setNotificationType("error");

        // Show the notification
        setShowNotification(true);
      })
      .finally(() => {
        setSubmitButtonValue("Send");
      });
  };

  return (
    <>
      <section className="enroll">
        <div className="wrapper">
          <div className="container">
            <form key={formKey} onSubmit={handleSubmit}>
              <h3>fill the form below to register</h3>
              <div className="form__group">
                <div>
                  <label htmlFor="firstName">First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    id="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label for="Name">Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    id="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form__group">
                <div>
                  <label for="Name">E-mail:</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    required
                  />
                </div>
                <div>
                  <label for="Name">Phone Number:</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    id="phoneNumber"
                    required
                  />
                </div>
              </div>
              <div className="form__group">
                <div className="full">
                  <label for="Name">Highest level of education:</label>
                  <input
                    type="text"
                    name="educationLevel"
                    value={educationLevel}
                    onChange={(e) => setEducationLevel(e.target.value)}
                    id="educationLevel"
                    required
                  />
                </div>
              </div>
              <div className="form__group">
                <div className="full">
                  <label for="Name">Certificate Obtained:</label>
                  <input
                    type="text"
                    name="certificateObtained"
                    value={certificateObtained}
                    onChange={(e) => setCertificateObtained(e.target.value)}
                    id="certificateObtained"
                    required
                  />
                </div>
              </div>
              <div className="form__group">
                <div className="full">
                  <label for="course">Choose a Course:</label>
                  <select
                    id="courseOption"
                    name="courseOption"
                    value={courseOption}
                    onChange={handleCourseOptionChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {courseOptions.map((courseOption) => (
                      <option key={courseOption} value={courseOption}>
                        {courseOption}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form__group">
                <div className="full">
                  <label for="passportPicture">
                    Upload a passport sized photograph
                  </label>
                  <input
                    type="file"
                    name="passportPicture"
                    onChange={handleFileChange}
                    key={`fileInput-${formKey}`}
                  />
                </div>
              </div>
              <div className="btnHolder">
                <input
                  type="submit"
                  className="btn3"
                  value={submitButtonValue}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
      <ScrollToTop />
      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default Enroll;
