import React from "react";
import { GetCourseInfo, ScrollToTop } from "../../components";

const UiUx = () => {
  return (
    <>
      <section className="bootCampHero">
        <div className="container">
          <div className="left">
            <div className="heroTxtContent">
              <h1>UI/UX</h1>
            </div>
            <div className="btnHolder">
              <a href="../enroll" className="btn4">
                Enroll
              </a>
            </div>
          </div>
          <div className="right"></div>
        </div>
      </section>
      <main>
        <div className="benefits container">
          <div className="whatYouWillLearn">
            <div className="content">
              {/* <h1>What You Will Learn</h1> */}
              <article>
                <p>
                  Embark on a transformative journey into the world of User
                  Interface (UI) and User Experience (UX) design with our
                  specialized course tailored for individuals seeking to craft
                  seamless and visually stunning digital experiences. Our UI/UX
                  Design program provides students with a holistic set of
                  skills, blending creativity and technical expertise to meet
                  the demands of the ever-evolving digital landscape.
                </p>
                <p>
                  Throughout the course, students will master the principles of
                  design thinking, delving into the psychology of user behavior
                  to create intuitive and engaging interfaces. Proficiency in
                  industry-standard design tools and software will be honed,
                  enabling students to conceptualize and prototype their ideas
                  effectively.
                </p>
                <p>
                  The curriculum emphasizes hands-on learning, with real-world
                  projects that mirror the complexities of the design industry.
                  From wireframing and prototyping to usability testing and
                  iteration, students will navigate the entire design process,
                  ensuring a comprehensive understanding of both UI and UX
                  disciplines.
                </p>
                <p>
                  Collaborative projects and industry-relevant case studies
                  enhance teamwork and problem-solving skills. Students will
                  explore the importance of empathy and user-centered design,
                  ensuring their creations not only meet technical
                  specifications but also resonate with the needs and desires of
                  the end-users.
                </p>
                <p>
                  Upon completion of the program, graduates will be
                  well-prepared to enter the thriving field of UI/UX design,
                  contributing their creativity and skills to industries ranging
                  from tech and e-commerce to healthcare and beyond. Join us and
                  turn your passion for design into a rewarding career shaping
                  the digital experiences of tomorrow.
                </p>
              </article>
            </div>
          </div>
          {/* <div className="pathWays">
            <div className="content">
              <h1>Employment Pathways</h1>
              <p>
                After successful completion of the training programme, Trainees
                have three employment pathways to choose from:
              </p>
              <ul>
                <li>
                  You can get an employment offer at one of our Service Centres.
                </li>
                <li>
                  You can use Veltetech as a platform to be self-employed as we
                  also encourage entrepreneurship.
                </li>
                <li>
                  You have an option to seek gainful employment on your own.
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="container">
          <GetCourseInfo />
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default UiUx;
