import React from "react";
import { GetCourseInfo, ScrollToTop } from "../../components";

const MobileDevelopment = () => {
  return (
    <>
      <section className="bootCampHero">
        <div className="container">
          <div className="left">
            <div className="heroTxtContent">
              <h1>Mobile Development</h1>
            </div>
            <div className="btnHolder">
              <a href="../enroll" className="btn4">
                Enroll
              </a>
            </div>
          </div>
          <div className="right"></div>
        </div>
      </section>
      <main>
        <div className="benefits container">
          <div className="whatYouWillLearn">
            <div className="content">
              {/* <h1>What You Will Learn</h1> */}
              <article>
                <p>
                  Dive into the cutting-edge realm of mobile development with
                  our specialized course, tailor-made for individuals passionate
                  about creating innovative and impactful mobile applications.
                  This program provides students with a comprehensive skill set
                  to thrive in the rapidly evolving landscape of mobile
                  technology.
                </p>
                <p>
                  Throughout the course, students will delve into the core
                  principles of mobile app development, gaining hands-on
                  experience with popular platforms such as iOS and Android.
                  They will become proficient in programming languages like
                  Swift and Kotlin, ensuring a solid foundation for crafting
                  engaging and user-friendly mobile applications.
                </p>
                <p>
                  Our curriculum emphasizes the development of both front-end
                  and back-end functionalities, enabling students to create
                  seamless and robust mobile experiences. From designing
                  intuitive user interfaces to implementing complex backend
                  logic, students will acquire the skills necessary to bring
                  their app ideas to life.
                </p>
                <p>
                  Real-world projects and collaborative exercises simulate the
                  challenges faced in the mobile development industry, fostering
                  problem-solving skills and teamwork. Students will also
                  explore emerging technologies such as augmented reality (AR)
                  and virtual reality (VR), staying at the forefront of the
                  latest trends in mobile app innovation.
                </p>
                <p>
                  Upon completion of the program, graduates will be
                  well-equipped to enter the dynamic field of mobile
                  development, ready to contribute to the creation of
                  cutting-edge applications. Join us and embark on a journey to
                  shape the future of mobile technology.
                </p>
              </article>
            </div>
          </div>
          {/* <div className="pathWays">
            <div className="content">
              <h1>Employment Pathways</h1>
              <p>
                After successful completion of the training programme, Trainees
                have three employment pathways to choose from:
              </p>
              <ul>
                <li>
                  You can get an employment offer at one of our Service Centres.
                </li>
                <li>
                  You can use Veltetech as a platform to be self-employed as we
                  also encourage entrepreneurship.
                </li>
                <li>
                  You have an option to seek gainful employment on your own.
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="container">
          <GetCourseInfo />
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default MobileDevelopment;
