export const navigationLinks = [
  {
    id: "1",
    name: "Home",
    url: "/",
  },
  {
    id: "2",
    name: "Services",
    url: "/services",
  },
  {
    id: "3",
    name: "Bootcamp",
    url: "/bootcamp",
  },
  {
    id: "4",
    name: "Portfolio",
    url: "/portfolio",
  },
  {
    id: "5",
    name: "about us",
    url: "/about",
  },

  {
    id: "6",
    name: "Contact Us",
    url: "/contact",
  },
];

export const ourServiceLinks = [
  {
    id: "1",
    name: "mineral fraud recoveries",
    url: "/mineral_fraud",
  },
  {
    id: "2",
    name: "mineral purchases",
    url: "/mineral_purchases",
  },
  {
    id: "3",
    name: "data protection services",
    url: "/data_protection",
  },
];

export const testimonies = [
  {
    id: "0",
    text: " Lorem, ipsum dolor sit amet consectetur adipisicing elit.Id omnis nemo quaerat eligendi unde ab hic ducimus nihil provident, architecto, dignissimos debitis veniam labore sit quisquam natus, non facere eos. ",
    person: "~ Natacha B (USA)",
  },
  {
    id: "1",
    text: " Lorem, ipsum dolor sit amet consectetur adipisicing elit.Id omnis nemo quaerat eligendi unde ab hic ducimus nihil provident, architecto, dignissimos debitis veniam labore sit quisquam natus, non facere eos. ",
    person: "~ Diane Yaa Asantewaa, Vinneumont Events (UK)",
  },
  {
    id: "2",
    text: " Lorem, ipsum dolor sit amet consectetur adipisicing elit.Id omnis nemo quaerat eligendi unde ab hic ducimus nihil provident, architecto, dignissimos debitis veniam labore sit quisquam natus, non facere eos. ",
    person: "~ Jade (USA)",
  },
];

export const testy = [
  {
    id: "0",
    text: " Lorem, ipsum dolor sit amet consectetur adipisicing elit.Id omnis nemo quaerat eligendi unde ab hic ducimus nihil provident, architecto, dignissimos debitis veniam labore sit quisquam natus, non facere eos. ",
    person: "~ Natacha B (USA)",
  },
  {
    id: "1",
    text: " Lorem, ipsum dolor sit amet consectetur adipisicing elit.Id omnis nemo quaerat eligendi unde ab hic ducimus nihil provident, architecto, dignissimos debitis veniam labore sit quisquam natus, non facere eos. ",
    person: "~ Diane Yaa Asantewaa, Vinneumont Events (UK)",
  },
  {
    id: "2",
    text: " Lorem, ipsum dolor sit amet consectetur adipisicing elit.Id omnis nemo quaerat eligendi unde ab hic ducimus nihil provident, architecto, dignissimos debitis veniam labore sit quisquam natus, non facere eos. ",
    person: "~ Jade (USA)",
  },
];
